var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('admin-title',{attrs:{"title":_vm.$route.meta.name}}),_c('div',{staticClass:"ad-container"},[_c('div',{staticClass:"center"},[_vm._m(0),_c('div',{staticClass:"content-container"},[_c('div',{class:['banner', _vm.type == 'banner' ? 'is-border' : ''],on:{"click":function($event){_vm.type = 'banner'}}},[(_vm.formData.positionList[_vm.getIndex('banner')])?[_c('el-carousel',{attrs:{"interval":2000,"height":"143px","arrow":"never"}},_vm._l((_vm.formData.positionList[_vm.getIndex('banner')]
                  .imageList),function(item){return _c('el-carousel-item',{key:item.imageUrl},[(item.imageUrl)?_c('img',{attrs:{"src":item.imageUrl}}):_c('i',{staticClass:"el-icon-plus icon-plus-img"})])}),1)]:_c('i',{staticClass:"el-icon-plus icon-plus-img"})],2),_vm._m(1),_c('div',{staticClass:"floor-pic"},[_c('div',{class:['left-pic', _vm.type == 'bannerLeft' ? 'is-border' : ''],on:{"click":function($event){_vm.type = 'bannerLeft'}}},[_c('el-carousel',{attrs:{"height":"214px","arrow":"never","indicator-position":"none"}},[(_vm.formData.positionList[_vm.getIndex('bannerLeft')])?_vm._l((_vm.formData.positionList[_vm.getIndex('bannerLeft')]
                    .imageList),function(item){return _c('el-carousel-item',{key:item.imageUrl},[(item.imageUrl)?_c('img',{attrs:{"src":item.imageUrl,"alt":""}}):_c('i',{staticClass:"el-icon-plus icon-plus-img"})])}):_c('i',{staticClass:"el-icon-plus icon-plus-img"})],2)],1),_c('div',{class:['right-pic', _vm.type == 'bannerRight' ? 'is-border' : ''],on:{"click":function($event){_vm.type = 'bannerRight'}}},[(_vm.formData.positionList[_vm.getIndex('bannerRight')])?_vm._l((_vm.formData.positionList[
                  _vm.getIndex('bannerRight')
                ].imageList),function(item,i){return _c('div',{key:i,class:['item-pic']},[(item.imageUrl)?_c('img',{attrs:{"src":item.imageUrl,"alt":""}}):_c('i',{staticClass:"el-icon-plus icon-plus-img"})])}):_c('i',{staticClass:"el-icon-plus icon-plus-img"})],2)]),_vm._m(2),_vm._m(3)])]),_c('div',{staticClass:"left"},[_c('el-divider',{attrs:{"content-position":"center"}},[_vm._v(_vm._s(_vm.title))]),(!_vm.type)?_c('el-empty',{attrs:{"description":"点击左侧虚线区域进行配置"}}):_vm._e(),_c('el-form',{ref:"form",attrs:{"model":_vm.formData,"label-width":"80px"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.type == 'banner'),expression:"type == 'banner'"}]},[_c('div',{staticClass:"banner"},[_c('el-button',{staticStyle:{"margin-bottom":"10px"},attrs:{"type":"primary","size":"small","icon":"el-icon-plus"},on:{"click":function($event){return _vm.addBanner('banner')}}},[_vm._v("新增一张图片")]),_c('div',{staticClass:"banner-title"},[_c('span',[_vm._v("序号")]),_c('span',[_vm._v("跳转范围")]),_c('span',[_vm._v("跳转类型")]),_c('span',[_vm._v("跳转地址")]),_c('span',[_vm._v("图片")]),_c('span',[_vm._v("操作 ")])]),(_vm.formData.positionList[_vm.getIndex('banner')])?_vm._l((_vm.formData.positionList[
                  _vm.getIndex('banner')
                ].imageList),function(banner,i){return _c('div',{key:i,staticClass:"banner-content"},[_c('span',[_vm._v(_vm._s(i + 1))]),_c('span',[_c('el-form-item',{attrs:{"label":"","label-width":"0"}},[_c('el-select',{attrs:{"placeholder":"请选择","size":"small"},model:{value:(banner.targetSide),callback:function ($$v) {_vm.$set(banner, "targetSide", $$v)},expression:"banner.targetSide"}},_vm._l((_vm.targetSideDict),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.name,"value":item.value}})}),1)],1)],1),_c('span',[_c('el-form-item',{attrs:{"label":"","label-width":"0"}},[_c('el-select',{attrs:{"placeholder":"请选择","size":"small"},model:{value:(banner.targetType),callback:function ($$v) {_vm.$set(banner, "targetType", $$v)},expression:"banner.targetType"}},_vm._l((_vm.targetTypeDict),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.name,"value":item.value}})}),1)],1)],1),_c('span',[_c('el-form-item',{attrs:{"label":"","label-width":"0"}},[_c('el-input',{attrs:{"placeholder":"请输入","size":"small"},model:{value:(banner.targetUrl),callback:function ($$v) {_vm.$set(banner, "targetUrl", $$v)},expression:"banner.targetUrl"}})],1)],1),_c('span',[_c('FileUpload',{attrs:{"name":banner.image,"url":banner.imageUrl},on:{"update:name":function($event){return _vm.$set(banner, "image", $event)}}})],1),_c('span',[_c('el-link',{staticStyle:{"margin-right":"20px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.deleteBanner(i, 'banner')}}},[_vm._v("删除")])],1)])}):_vm._e()],2),(_vm.formData.positionList[_vm.getIndex('banner')])?[(
                !_vm.formData.positionList[[_vm.getIndex('banner')]].imageList.length
              )?_c('el-empty',{attrs:{"description":"暂无图片"}}):_vm._e()]:_c('el-empty',{attrs:{"description":"暂无图片"}})],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.type == 'bannerLeft'),expression:"type == 'bannerLeft'"}]},[_c('div',{staticClass:"banner"},[_c('el-button',{staticStyle:{"margin-bottom":"10px"},attrs:{"type":"primary","size":"small","icon":"el-icon-plus"},on:{"click":function($event){return _vm.addBanner('bannerLeft')}}},[_vm._v("新增一张图片")]),_c('div',{staticClass:"banner-title"},[_c('span',[_vm._v("序号")]),_c('span',[_vm._v("跳转范围")]),_c('span',[_vm._v("跳转类型")]),_c('span',[_vm._v("跳转地址")]),_c('span',[_vm._v("图片")]),_c('span',[_vm._v("操作 ")])]),(_vm.formData.positionList[_vm.getIndex('bannerLeft')])?_vm._l((_vm.formData.positionList[
                  _vm.getIndex('bannerLeft')
                ].imageList),function(banner,i){return _c('div',{key:i,staticClass:"banner-content"},[_c('span',[_vm._v(_vm._s(i + 1))]),_c('span',[_c('el-form-item',{attrs:{"label":"","label-width":"0"}},[_c('el-select',{attrs:{"placeholder":"请选择","size":"small"},model:{value:(banner.targetSide),callback:function ($$v) {_vm.$set(banner, "targetSide", $$v)},expression:"banner.targetSide"}},_vm._l((_vm.targetSideDict),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.name,"value":item.value}})}),1)],1)],1),_c('span',[_c('el-form-item',{attrs:{"label":"","label-width":"0"}},[_c('el-select',{attrs:{"placeholder":"请选择","size":"small"},model:{value:(banner.targetType),callback:function ($$v) {_vm.$set(banner, "targetType", $$v)},expression:"banner.targetType"}},_vm._l((_vm.targetTypeDict),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.name,"value":item.value}})}),1)],1)],1),_c('span',[_c('el-form-item',{attrs:{"label":"","label-width":"0"}},[_c('el-input',{attrs:{"placeholder":"请输入","size":"small"},model:{value:(banner.targetUrl),callback:function ($$v) {_vm.$set(banner, "targetUrl", $$v)},expression:"banner.targetUrl"}})],1)],1),_c('span',[_c('FileUpload',{attrs:{"name":banner.image,"url":banner.imageUrl},on:{"update:name":function($event){return _vm.$set(banner, "image", $event)}}})],1),_c('span',[_c('el-link',{staticStyle:{"margin-right":"20px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.deleteBanner(i, 'bannerLeft')}}},[_vm._v("删除")])],1)])}):_vm._e()],2),(_vm.formData.positionList[_vm.getIndex('bannerLeft')])?[(
                !_vm.formData.positionList[_vm.getIndex('bannerLeft')].imageList
                  .length
              )?_c('el-empty',{attrs:{"description":"暂无图片"}}):_vm._e()]:_c('el-empty',{attrs:{"description":"暂无图片"}})],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.type == 'bannerRight'),expression:"type == 'bannerRight'"}]},[_c('div',{staticClass:"banner"},[_c('el-button',{directives:[{name:"checkbtn",rawName:"v-checkbtn",value:(_vm.isShowBtn(_vm.AUTH_BTN.operation_ad_add)),expression:"isShowBtn(AUTH_BTN.operation_ad_add)"}],staticStyle:{"margin-bottom":"10px"},attrs:{"disabled":_vm.isShow >= 3,"type":"primary","size":"small","icon":"el-icon-plus"},on:{"click":function($event){return _vm.addBanner('bannerRight')}}},[_vm._v(_vm._s(_vm.isShow >= 3 ? "最多添加3张" : "新增一张图片"))]),_c('div',{staticClass:"banner-title"},[_c('span',[_vm._v("序号")]),_c('span',[_vm._v("跳转范围")]),_c('span',[_vm._v("跳转类型")]),_c('span',[_vm._v("跳转地址")]),_c('span',[_vm._v("图片")]),_c('span',[_vm._v("操作 ")])]),(_vm.formData.positionList[_vm.getIndex('bannerRight')])?_vm._l((_vm.formData.positionList[
                  _vm.getIndex('bannerRight')
                ].imageList),function(banner,i){return _c('div',{key:i,staticClass:"banner-content"},[_c('span',[_vm._v(_vm._s(i + 1))]),_c('span',[_c('el-form-item',{attrs:{"label":"","label-width":"0"}},[_c('el-select',{attrs:{"placeholder":"请选择","size":"small"},model:{value:(banner.targetSide),callback:function ($$v) {_vm.$set(banner, "targetSide", $$v)},expression:"banner.targetSide"}},_vm._l((_vm.targetSideDict),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.name,"value":item.value}})}),1)],1)],1),_c('span',[_c('el-form-item',{attrs:{"label":"","label-width":"0"}},[_c('el-select',{attrs:{"placeholder":"请选择","size":"small"},model:{value:(banner.targetType),callback:function ($$v) {_vm.$set(banner, "targetType", $$v)},expression:"banner.targetType"}},_vm._l((_vm.targetTypeDict),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.name,"value":item.value}})}),1)],1)],1),_c('span',[_c('el-form-item',{attrs:{"label":"","label-width":"0"}},[_c('el-input',{attrs:{"placeholder":"请输入","size":"small"},model:{value:(banner.targetUrl),callback:function ($$v) {_vm.$set(banner, "targetUrl", $$v)},expression:"banner.targetUrl"}})],1)],1),_c('span',[_c('FileUpload',{attrs:{"name":banner.image,"url":banner.imageUrl},on:{"update:name":function($event){return _vm.$set(banner, "image", $event)}}})],1),_c('span',[_c('el-link',{staticStyle:{"margin-right":"20px"},attrs:{"disabled":!_vm.isShowBtn(_vm.AUTH_BTN.operation_ad_del),"type":"primary"},on:{"click":function($event){return _vm.deleteBanner(i, 'bannerRight')}}},[_vm._v("删除")])],1)])}):_vm._e()],2),(_vm.formData.positionList[_vm.getIndex('bannerRight')])?[(
                !_vm.formData.positionList[_vm.getIndex('bannerRight')].imageList
                  .length
              )?_c('el-empty',{attrs:{"description":"暂无图片"}}):_vm._e()]:_c('el-empty',{attrs:{"description":"暂无图片"}})],2),_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.type),expression:"type"}],staticStyle:{"margin-top":"20px","text-align":"center"},attrs:{"label":"","label-width":"0"}},[_c('el-button',{attrs:{"disabled":!_vm.isShowBtn(_vm.AUTH_BTN.operation_ad_submit),"type":"primary","size":"small"},on:{"click":_vm.save}},[_vm._v("提交")]),_c('el-button',{attrs:{"type":"","size":"small"},on:{"click":_vm.getAdDetail}},[_vm._v("取消")])],1)],1)],1)]),_c('div',{staticStyle:{"height":"20px"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('img',{attrs:{"src":require("@/assets/header-icon.png"),"alt":""}}),_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/header-search.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav"},[_c('img',{attrs:{"src":require("@/assets/nav.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"floor-icon"},[_c('img',{attrs:{"src":require("@/assets/floor-icon.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"goods"},[_c('img',{attrs:{"src":require("@/assets/goods.png"),"alt":""}}),_c('img',{attrs:{"src":require("@/assets/goods.png"),"alt":""}})])
}]

export { render, staticRenderFns }