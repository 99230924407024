<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="ad-container">
      <div class="center">
        <div class="header">
          <img src="@/assets/header-icon.png" alt="" />
          <img style="width: 100%" src="@/assets/header-search.png" alt="" />
        </div>
        <div class="content-container">
          <div
            :class="['banner', type == 'banner' ? 'is-border' : '']"
            @click="type = 'banner'"
          >
            <template v-if="formData.positionList[getIndex('banner')]">
              <el-carousel :interval="2000" height="143px" arrow="never">
                <el-carousel-item
                  v-for="item in formData.positionList[getIndex('banner')]
                    .imageList"
                  :key="item.imageUrl"
                >
                  <img v-if="item.imageUrl" :src="item.imageUrl" />
                  <i v-else class="el-icon-plus icon-plus-img"></i>
                </el-carousel-item>
              </el-carousel>
            </template>
            <i v-else class="el-icon-plus icon-plus-img"></i>
          </div>
          <div class="nav">
            <img src="@/assets/nav.png" alt="" />
          </div>
          <div class="floor-pic">
            <div
              :class="['left-pic', type == 'bannerLeft' ? 'is-border' : '']"
              @click="type = 'bannerLeft'"
            >
              <el-carousel
                height="214px"
                arrow="never"
                indicator-position="none"
              >
                <template v-if="formData.positionList[getIndex('bannerLeft')]">
                  <el-carousel-item
                    v-for="item in formData.positionList[getIndex('bannerLeft')]
                      .imageList"
                    :key="item.imageUrl"
                  >
                    <img v-if="item.imageUrl" :src="item.imageUrl" alt="" />
                    <!-- <img v-else src="@/assets/big-pic.png" alt="" /> -->
                    <i v-else class="el-icon-plus icon-plus-img"></i>
                  </el-carousel-item>
                </template>
                <i v-else class="el-icon-plus icon-plus-img"></i>
              </el-carousel>
            </div>
            <div
              :class="['right-pic', type == 'bannerRight' ? 'is-border' : '']"
              @click="type = 'bannerRight'"
            >
              <template v-if="formData.positionList[getIndex('bannerRight')]">
                <div
                  :class="['item-pic']"
                  v-for="(item, i) in formData.positionList[
                    getIndex('bannerRight')
                  ].imageList"
                  :key="i"
                >
                  <img v-if="item.imageUrl" :src="item.imageUrl" alt="" />
                  <i v-else class="el-icon-plus icon-plus-img"></i>
                </div>
                <!-- <div :class="['item-pic']">
                  <img
                    v-if="formData.positionList[getIndex('bannerRight')].imageList[1].imageUrl"
                    :src="formData.positionList[getIndex('bannerRight')].imageList[1].imageUrl"
                    alt=""
                  />
                  <i v-else class="el-icon-plus icon-plus-img"></i>
                </div>
                <div :class="['item-pic']">
                  <img
                    v-if="formData.positionList[getIndex('bannerRight')].imageList[2].imageUrl"
                    :src="formData.positionList[getIndex('bannerRight')].imageList[2].imageUrl"
                    alt=""
                  />
                  <i v-else class="el-icon-plus icon-plus-img"></i>
                </div> -->
              </template>
              <i v-else class="el-icon-plus icon-plus-img"></i>
            </div>
          </div>
          <div class="floor-icon">
            <img src="@/assets/floor-icon.png" alt="" />
          </div>
          <div class="goods">
            <img src="@/assets/goods.png" alt="" />
            <img src="@/assets/goods.png" alt="" />
          </div>
        </div>
      </div>
      <div class="left">
        <el-divider content-position="center">{{ title }}</el-divider>
        <el-empty
          v-if="!type"
          description="点击左侧虚线区域进行配置"
        ></el-empty>

        <el-form ref="form" :model="formData" label-width="80px">
          <!-- banner -->
          <div v-show="type == 'banner'">
            <div class="banner">
              <el-button
                style="margin-bottom: 10px"
                type="primary"
                size="small"
                icon="el-icon-plus"
                @click="addBanner('banner')"
                >新增一张图片</el-button
              >
              <div class="banner-title">
                <span>序号</span>
                <span>跳转范围</span>
                <span>跳转类型</span>
                <span>跳转地址</span>
                <span>图片</span>
                <span>操作 </span>
              </div>
              <template v-if="formData.positionList[getIndex('banner')]">
                <div
                  class="banner-content"
                  v-for="(banner, i) in formData.positionList[
                    getIndex('banner')
                  ].imageList"
                  :key="i"
                >
                  <span>{{ i + 1 }}</span>
                  <span>
                    <el-form-item label="" label-width="0">
                      <el-select
                        v-model="banner.targetSide"
                        placeholder="请选择"
                        size="small"
                      >
                        <el-option
                          v-for="item in targetSideDict"
                          :key="item.value"
                          :label="item.name"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </span>
                  <span>
                    <el-form-item label="" label-width="0">
                      <el-select
                        v-model="banner.targetType"
                        placeholder="请选择"
                        size="small"
                      >
                        <el-option
                          v-for="item in targetTypeDict"
                          :key="item.value"
                          :label="item.name"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </span>
                  <span>
                    <el-form-item label="" label-width="0">
                      <el-input
                        v-model="banner.targetUrl"
                        placeholder="请输入"
                        size="small"
                      ></el-input>
                    </el-form-item>
                  </span>
                  <span>
                    <FileUpload
                      :name.sync="banner.image"
                      :url="banner.imageUrl"
                    ></FileUpload>
                  </span>
                  <span>
                    <el-link
                      style="margin-right: 20px"
                      type="primary"
                      @click="deleteBanner(i, 'banner')"
                      >删除</el-link
                    >
                  </span>
                </div>
              </template>
            </div>
            <template v-if="formData.positionList[getIndex('banner')]">
              <el-empty
                v-if="
                  !formData.positionList[[getIndex('banner')]].imageList.length
                "
                description="暂无图片"
              ></el-empty>
            </template>
            <el-empty v-else description="暂无图片"></el-empty>
          </div>
          <!-- bannerLeft -->
          <div v-show="type == 'bannerLeft'">
            <div class="banner">
              <el-button
                style="margin-bottom: 10px"
                type="primary"
                size="small"
                icon="el-icon-plus"
                @click="addBanner('bannerLeft')"
                >新增一张图片</el-button
              >
              <div class="banner-title">
                <span>序号</span>
                <span>跳转范围</span>
                <span>跳转类型</span>
                <span>跳转地址</span>
                <span>图片</span>
                <span>操作 </span>
              </div>

              <template v-if="formData.positionList[getIndex('bannerLeft')]">
                <div
                  class="banner-content"
                  v-for="(banner, i) in formData.positionList[
                    getIndex('bannerLeft')
                  ].imageList"
                  :key="i"
                >
                  <span>{{ i + 1 }}</span>
                  <span>
                    <el-form-item label="" label-width="0">
                      <el-select
                        v-model="banner.targetSide"
                        placeholder="请选择"
                        size="small"
                      >
                        <el-option
                          v-for="item in targetSideDict"
                          :key="item.value"
                          :label="item.name"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </span>
                  <span>
                    <el-form-item label="" label-width="0">
                      <el-select
                        v-model="banner.targetType"
                        placeholder="请选择"
                        size="small"
                      >
                        <el-option
                          v-for="item in targetTypeDict"
                          :key="item.value"
                          :label="item.name"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </span>
                  <span>
                    <el-form-item label="" label-width="0">
                      <el-input
                        v-model="banner.targetUrl"
                        placeholder="请输入"
                        size="small"
                      ></el-input>
                    </el-form-item>
                  </span>
                  <span>
                    <FileUpload
                      :name.sync="banner.image"
                      :url="banner.imageUrl"
                    ></FileUpload>
                  </span>
                  <span>
                    <el-link
                      style="margin-right: 20px"
                      type="primary"
                      @click="deleteBanner(i, 'bannerLeft')"
                      >删除</el-link
                    >
                  </span>
                </div>
              </template>
            </div>
            <template v-if="formData.positionList[getIndex('bannerLeft')]">
              <el-empty
                v-if="
                  !formData.positionList[getIndex('bannerLeft')].imageList
                    .length
                "
                description="暂无图片"
              ></el-empty>
            </template>
            <el-empty v-else description="暂无图片"></el-empty>
          </div>
          <!-- bannerRight -->
          <div v-show="type == 'bannerRight'">
            <div class="banner">
              <el-button
                v-checkbtn="isShowBtn(AUTH_BTN.operation_ad_add)"
                :disabled="isShow >= 3"
                style="margin-bottom: 10px"
                type="primary"
                size="small"
                icon="el-icon-plus"
                @click="addBanner('bannerRight')"
                >{{ isShow >= 3 ? "最多添加3张" : "新增一张图片" }}</el-button
              >
              <div class="banner-title">
                <span>序号</span>
                <span>跳转范围</span>
                <span>跳转类型</span>
                <span>跳转地址</span>
                <span>图片</span>
                <span>操作 </span>
              </div>
              <template v-if="formData.positionList[getIndex('bannerRight')]">
                <div
                  class="banner-content"
                  v-for="(banner, i) in formData.positionList[
                    getIndex('bannerRight')
                  ].imageList"
                  :key="i"
                >
                  <span>{{ i + 1 }}</span>
                  <span>
                    <el-form-item label="" label-width="0">
                      <el-select
                        v-model="banner.targetSide"
                        placeholder="请选择"
                        size="small"
                      >
                        <el-option
                          v-for="item in targetSideDict"
                          :key="item.value"
                          :label="item.name"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </span>
                  <span>
                    <el-form-item label="" label-width="0">
                      <el-select
                        v-model="banner.targetType"
                        placeholder="请选择"
                        size="small"
                      >
                        <el-option
                          v-for="item in targetTypeDict"
                          :key="item.value"
                          :label="item.name"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </span>
                  <span>
                    <el-form-item label="" label-width="0">
                      <el-input
                        v-model="banner.targetUrl"
                        placeholder="请输入"
                        size="small"
                      ></el-input>
                    </el-form-item>
                  </span>
                  <span>
                    <FileUpload
                      :name.sync="banner.image"
                      :url="banner.imageUrl"
                    ></FileUpload>
                  </span>
                  <span>
                    <el-link
                      :disabled="!isShowBtn(AUTH_BTN.operation_ad_del)"
                      style="margin-right: 20px"
                      type="primary"
                      @click="deleteBanner(i, 'bannerRight')"
                      >删除</el-link
                    >
                  </span>
                </div>
              </template>
            </div>
            <template v-if="formData.positionList[getIndex('bannerRight')]">
              <el-empty
                v-if="
                  !formData.positionList[getIndex('bannerRight')].imageList
                    .length
                "
                description="暂无图片"
              ></el-empty>
            </template>
            <el-empty v-else description="暂无图片"></el-empty>
          </div>
          <el-form-item
            v-show="type"
            label=""
            label-width="0"
            style="margin-top: 20px; text-align: center"
          >
            <el-button
              :disabled="!isShowBtn(AUTH_BTN.operation_ad_submit)"
              type="primary"
              size="small"
              @click="save"
              >提交</el-button
            >
            <el-button type="" size="small" @click="getAdDetail"
              >取消</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- <SelectLink ref="link"></SelectLink> -->
    <div style="height: 20px"></div>
  </div>
</template>

<script>
import { authBtnMixin } from "@/mixins/authBtnMixin";
import { targetSideDict, targetTypeDict } from "@/enum/dict/index.js";
import { getAdDetailAPI, saveAdAPI } from "./api";
import SelectLink from "./select-link.vue";
import FileUpload from "@/components/file-upload/index.vue";
import SelectDict from "@/views/components/select-dict.vue";
export default {
  name: "List",
  mixins: [authBtnMixin],
  components: { FileUpload },
  data() {
    return {
      isShow: 0,
      bannerList: [{ id: 1 }, { id: 2 }],
      bigPicList: [{ id: 1 }],
      type: "",
      formData: {
        pageCode: "home",
        positionList: [
          // {
          //   positionCode: 'banner',
          //   imageList: [
          //     {
          //       image: '',
          //       imageUrl: '',
          //       targetSide: 1,
          //       targetType: 1,
          //       targetUrl: ''
          //     }
          //   ]
          // },
          // {
          //   positionCode: 'bannerLeft',
          //   imageList: [
          //     {
          //       image: '',
          //       imageUrl: '',
          //       targetSide: 1,
          //       targetType: 1,
          //       targetUrl: ''
          //     }
          //   ]
          // },
          // {
          //   positionCode: 'bannerRight',
          //   imageList: [
          //     {
          //       image: '',
          //       imageUrl: '',
          //       targetSide: 1,
          //       targetType: 1,
          //       targetUrl: ''
          //     },
          //     {
          //       image: '',
          //       imageUrl: '',
          //       targetSide: 1,
          //       targetType: 1,
          //       targetUrl: ''
          //     },
          //     {
          //       image: '',
          //       imageUrl: '',
          //       targetSide: 1,
          //       targetType: 1,
          //       targetUrl: ''
          //     }
          //   ]
          // }
        ],
      },
      // 字典
      targetTypeDict,
      targetSideDict,
    };
  },

  mounted() {
    this.getAdDetail();
  },
  computed: {
    title() {
      let name = "图片设置";
      switch (this.type) {
        case "banner":
          name = "顶部banner";
          break;
        case "bannerLeft":
          name = "广告位左侧";
          break;
        case "bannerRight":
          name = "广告位右侧";
          break;

        default:
          break;
      }
      return name;
    },
    isDis() {
      let show = "";
      const { positionList } = this.formData;
      const arr = positionList.find(
        (item) => item.positionCode == "bannerRight",
      ).imageList;
      show = arr.length;
      return show;
    },
  },
  methods: {
    async getAdDetail() {
      this.formData = await getAdDetailAPI("home");
      if (this.formData.positionList[this.getIndex("bannerRight")]) {
        this.isShow =
          this.formData.positionList[
            this.getIndex("bannerRight")
          ].imageList.length;
      }
    },
    // 新增一张banner图
    addBanner(position) {
      console.log(this.getIndex(position));
      let data = {
        image: "",
        imageUrl: "",
        targetSide: 1,
        targetType: 1,
        targetUrl: "",
      };
      if (position == "banner") {
        if (this.getIndex(position) == -1) {
          this.formData.positionList.push({
            positionCode: position,
            imageList: [data],
          });
        } else {
          this.formData.positionList[this.getIndex(position)].imageList.push(
            data,
          );
        }
      } else if (position == "bannerLeft") {
        if (this.getIndex(position) == -1) {
          this.formData.positionList.push({
            positionCode: position,
            imageList: [data],
          });
        } else {
          this.formData.positionList[this.getIndex(position)].imageList.push(
            data,
          );
        }
      } else if (position == "bannerRight") {
        if (this.getIndex(position) == -1) {
          this.formData.positionList.push({
            positionCode: position,
            imageList: [data],
          });
        } else {
          this.formData.positionList[this.getIndex(position)].imageList.push(
            data,
          );
        }
        // console.log(this.formData.positionList[this.getIndex(position)].imageList.length)
        this.isShow =
          this.formData.positionList[this.getIndex(position)].imageList.length;
      }
    },
    // 删除一张banner图
    deleteBanner(i, position) {
      this.formData.positionList[this.getIndex(position)].imageList.splice(
        i,
        1,
      );
      if (position == "bannerRight") {
        this.isShow =
          this.formData.positionList[this.getIndex(position)].imageList.length;
      }
      // if (position == 'banner') {
      //   this.formData.positionList[this.getIndex(position)].imageList.splice(i, 1)
      // } else if (position == 'bannerLeft') {
      //   this.formData.positionList[this.getIndex(position)].imageList.splice(i, 1)
      // } else if (position == 'bannerRight') {
      //   this.formData.positionList[this.getIndex(position)].imageList.splice(i, 1)
      // }
    },

    // 保存
    save() {
      saveAdAPI(this.formData).then(() => {
        this.$message.success("保存成功");
        this.getAdDetail();
      });
    },
    getIndex(position) {
      let index = -1;
      this.formData.positionList.forEach((item, i) => {
        if (item.positionCode == position) {
          index = i;
        }
      });
      return index;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .ad-container {
    display: flex;
    justify-content: space-between;
    .center,
    .right {
      width: 375px;
      min-height: 600px;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
      .right-content {
        .banner-item {
          background-color: #f0f2f5;
          padding: 10px;
          margin-bottom: 15px;
          border-radius: 6px;
          .banner-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .banner-upload {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;
            .banner-upload-left {
              flex: 1;
              .pic-name,
              .pic-link {
                span {
                  margin-right: 20px;
                  &:first-child {
                    color: rgba(0, 0, 0, 0.65);
                  }
                }
              }
              .pic-name {
                margin-bottom: 15px;
              }
            }
          }
        }
      }
    }
    .center {
      .header {
        margin-bottom: 15px;
      }
      .content-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        .banner {
          cursor: pointer;
          width: 343px;
          height: 148px;
          border: 2px dashed #409eff;
          box-sizing: border-box;

          img {
            width: 100%;
          }
          .icon-plus-img {
            color: #8c939d;
            font-size: 28px;
            line-height: 140px;
            text-align: center;
            width: 100%;
          }
        }
        .nav {
          display: flex;
          justify-content: center;
          margin: 15px 0;
          width: 343px;

          img {
            width: 100%;
          }
        }
        .floor-pic {
          height: 242px;
          width: 343px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          .left-pic {
            cursor: pointer;
            width: 154px;
            height: 218px;
            border: 2px dashed #409eff;
            img {
              width: 100%;
              height: 100%;
            }
            .icon-plus-img {
              color: #8c939d;
              font-size: 28px;
              line-height: 210px;
              text-align: center;
              width: 100%;
            }
          }
          .right-pic {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 157px;
            height: 218px;
            border: 2px dashed #409eff;
            .item-pic {
              cursor: pointer;
              width: 157px;
              height: 67px;
              img {
                width: 97%;
                height: 100%;
              }
              .icon-plus-img {
                border: 1px dashed #8c939d;
                width: 97%;
                text-align: center;
                font-size: 28px;
                color: #8c939d;
                line-height: 72px;
                height: 72px;
                margin: 3px 0;
              }
            }
            .icon-plus-img {
              color: #8c939d;
              font-size: 28px;
              line-height: 215px;
              text-align: center;
              width: 100%;
            }
          }
        }
        .floor-icon {
          display: flex;
          justify-content: center;
          width: 343px;
          margin: 8px 0;
          img {
            width: 100%;
          }
        }
        .goods {
          display: flex;
          justify-content: space-between;
          img {
            margin: 0 5px;
            width: 168px;
          }
        }
        .is-border {
          border: 2px dashed #f56c6c !important;
        }
      }
    }
    .right {
      padding: 16px;
      .title {
        font-size: 15px;
        font-weight: 600;
      }
    }
    .left {
      min-width: 800px;
      flex: 1;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
      margin-left: 30px;
      .el-form {
        padding: 0 10px;
        .el-form-item {
          margin-bottom: 0;
        }
        .banner {
          .banner-title {
            background-color: #f4f5f9;
            padding: 10px 20px;
            display: flex;
            & > span {
              padding-right: 10px;
              flex: 2;
              &:nth-last-child(2) {
                flex: 1;
              }
              &:last-child,
              &:first-child {
                flex: 0.6;
              }
            }
          }
          .banner-content {
            display: flex;
            background-color: #fbfbfb;
            padding: 10px 20px;
            border-bottom: 1px solid #e4e5e9;
            & > span {
              padding-right: 10px;
              flex: 2;
              display: flex;
              align-items: center;

              &:nth-last-child(2) {
                flex: 1;
              }
              &:last-child,
              &:first-child {
                flex: 0.6;
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
  }
  .avatar {
    width: 70px;
    height: 70px;
    display: block;
  }
}
</style>
